import React from "react";
import theme from "theme";
import { Theme, Image, Text, Link, Box, Section, Em, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Прокат TrailBlaze 
			</title>
			<meta name={"description"} content={"Відчуйте гострі відчуття від бездоріжжя з нашим преміальним прокатом квадроциклів та квадроциклів"} />
			<meta property={"og:title"} content={"Головна | Прокат TrailBlaze "} />
			<meta property={"og:description"} content={"Відчуйте гострі відчуття від бездоріжжя з нашим преміальним прокатом квадроциклів та квадроциклів"} />
			<meta property={"og:image"} content={"https://biz.vortexialbiz.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://biz.vortexialbiz.com/img/0569345.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<meta name={"msapplication-TileImage"} content={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<meta name={"msapplication-TileColor"} content={"https://biz.vortexialbiz.com/img/0569345.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" background="--color-lightD1" quarkly-title="Hero-13">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Image
					src="https://biz.vortexialbiz.com/img/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					width="60%"
					padding="80px 80px 80px 80px"
					lg-width="100%"
					md-padding="50px 50px 80px 50px"
					sm-padding="50px 25px 80px 25px"
				>
					<Text
						margin="0px 0px 40px 0px"
						font="normal 500 72px/1.2 --fontFamily-serifGeorgia"
						color="#061e11"
						lg-margin="0px 0px 35px 0px"
						md-font="normal 500 52px/1.2 --fontFamily-serifGeorgia"
						sm-font="normal 500 42px/1.2 --fontFamily-serifGeorgia"
					>
						TrailBlaze -<br/> ваша пригода, наш досвід
					</Text>
					<Text
						margin="0px 0px 40px 0px"
						font="--base"
						color="--greyD3"
						md-text-align="center"
						md-margin="0px 0px 20px 0px"
					>
						У TrailBlaze Rentals ми пропонуємо найкращі пригоди на бездоріжжі. Наш парк найсучасніших квадроциклів та всюдиходів ідеально підходить для тих, хто хоче дослідити дику та пересічену місцевість. Незалежно від того, чи ви досвідчений гонщик, чи новачок у бездоріжжі, у нас є відповідний транспортний засіб для вас. Приготуйтеся вирушити у захоплюючу подорож, яку ви запам'ятаєте на довгі роки.
					</Text>
					<Link href="/contact-us" color="--darkL1" font="normal 600 28px/1.2 --fontFamily-serifGeorgia">
						Дізнатись більше &gt;&gt;
					</Link>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="40%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Чому варто обрати TrailBlaze Rentals?
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Ми пропонуємо широкий вибір варіантів оренди, щоб задовольнити ваші потреби, незалежно від того, чи плануєте ви індивідуальну поїздку, сімейну мандрівку чи групову експедицію. Кожна оренда включає вичерпний інструктаж та інструкції з безпеки, щоб гарантувати, що ваша пригода пройде гладко. Для тих, хто віддає перевагу екскурсіям, ми також пропонуємо тури під керівництвом досвідчених гідів.
				</Text>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="60%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Високоякісні транспортні засоби: 
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						У нашому автопарку представлені найновіші моделі квадроциклів і всюдиходів, що гарантують найвищу продуктивність і надійність.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Безпека в пріоритеті
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Ваша безпека є нашою головною турботою, тому ми проводимо детальні інструктажі, надаємо захисне спорядження та добре доглянуте обладнання.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Виняткове обслуговування клієнтів
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Наші привітні та компетентні співробітники допоможуть вам від моменту бронювання до повернення автомобіля.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Гнучкі варіанти оренди
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Ми пропонуємо різноманітні пакети оренди, незалежно від того, чи потрібен вам автомобіль на кілька годин або на цілий день.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="url() 0 0 no-repeat" quarkly-title="About-11">
			<Override
				slot="SectionContent"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				width="20%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
				lg-width="30%"
			>
				<Image
					src="https://biz.vortexialbiz.com/img/2.jpg"
					md-margin="0px 0px 20px 0px"
					width="200px"
					height="200px"
					object-fit="cover"
					border-radius="50%"
					lg-width="200px"
				/>
			</Box>
			<Box
				width="80%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				align-items="flex-start"
				padding="0px 128px 0px 128px"
				lg-padding="0px 64px 0px 64px"
				lg-width="70%"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="32px 0px 18px 0px" font="--headline2" md-margin="24px 0px 30px 0px" sm-font="--headline3">
					
					<Span color="--dark">
					Відповідайте на поклик дикої природи з TrailBlaze Rentals!
					</Span>
				</Text>
				<Text margin="0px 0px 38px 0px" color="--darkL2" font="--lead">
					<Em>
					Ви готові дослідити прекрасне на свіжому повітрі? Забронюйте свій квадроцикл або всюдихід зараз і почніть свою пригоду з TrailBlaze Rentals. Ми обіцяємо досвід, сповнений азарту, приголомшливих пейзажів та незабутніх спогадів. Не зволікайте - ваша пригода на бездоріжжі чекає!
					</Em>
				</Text>
			</Box>
		</Section>
		<Components.Images />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});